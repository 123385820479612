// @ts-strict-ignore
import camelcaseKeys from 'camelcase-keys';
import snakecase from 'snakecase-keys';
import { saveAs } from 'file-saver';
import cache from 'lscache';
import { type UseMutateAsyncFunction } from '@tanstack/react-query';

import translate from '../../utils/i18n/SwappingIntlProvider';
import HttpError from '../../utils/apis/HttpError';
import store from '../../store/store';
import { buildHeaders, buildUrl, buildRequest, fetchRequest } from '../../utils/apis/RestApi';
import { type HttpMethod } from '../../utils/apis/restApiUtils';
import {
  type GetInterventionsIdsParams,
  type WorkshopIntervention,
  type CommentType,
  type InterventionState,
  type FooterInfos,
  type SparePartWorkshop,
  type CreationResponse,
  type RawShippingReturn,
  type ShippingResponse,
  type CreationResponseRaw,
  type StateParam,
  type RegionalWorkshopDelay,
  type Delay,
  type InterventionStateRaw,
  type InterventionProduct,
  type ToDoEvent,
  InterventionType,
  type InterventionTypeRaw,
  CustomerAlertType,
  type ServicesPogoBody,
  type SparePartsPogoBody,
  type PurchaseIntervention,
  type WboGlobalSearchRaw,
  type WorkshopInterventionRaw,
  type ServiceWorkshopRaw,
} from './intervention.type';
import { type Customer } from '../customer/customer.types';
import { handleHttpErrors, handle404Errors } from '../../business/helpers';
import { minutesToMidnight, truncateId } from '../../utils/utils';
import { printBlob } from '../../utils/utils-print';
import { cacheErrorLog, createLog } from '../logger';
import { finishedInterventions, fillInterventionsInformation, buildGlobalSearchInterventionFromRaw } from '../../business/interventionHelper';
import { selectStoreId } from '../../store/selectors/userInfo';
import { handleEnforcedServices } from '../../business/entityHelper';
import { type MasterDataArticle } from '../masterdata/masterdata.type';
import { type RedInformations } from '../defloc/defloc.type';
import { type Filter } from '../../components/Filters/Filter';
import { type Intervention } from '../../components/Interventions/Interventions';
import { InterventionStatus } from '../../business/interventionButtonMenu';
import { chunkArray } from '../ilink';
import { type Entity, type ILinkProduct, LevelType, type SparePartEntity } from '../ilink.type';
import { type SearchIntervention } from '../../components/TableSearch/TableSearch';
import { translatePogoToPogoId } from './intervention.const';
import { getCustomerName } from '../../business/customerHelper';
import { selectAuthorizedPogos } from '../../business/pogoHelper';
import { fetchFullCustomerById } from '../member/member';
import { CustomerCommunicationType } from '../startup/startup.type';
import { type I18nState } from '../../store/i18nSlice';
import { getArticlesDetails } from '../masterdata/masterdata';
import { formatISOWithTimeZone, getCurrentDate, getEndOfDay, getStartOfDay } from '../../utils/date/date-utils';
import { type ParcelIntervention, type Parcel, type ParcelNumberRaw } from './parcel.type';
import {
  type UsePatchInterventionMutationParams,
  type UsePostInterventionServicesAndSparePartsMutationParams,
  type UsePatchInterventionServiceMutationParams,
  type ServicesAndSpareParts,
} from './mutations.types';

const logger = createLog('api/interventions');

const interventionsStorageKey = 'interventions-v3';

/**
 *
 * @deprecated use selectStoreId
 */
export const getStoreId = () => store.getState().userInfo.selectedSite.id;
export const getStoreCountry = () => store.getState().userInfo.selectedSite.address.countryCode;

export const buildBody = <T extends Object>(body?: T | FormData, emptyString?: boolean, isFileRequest?: boolean) =>
  isFileRequest ? (body as FormData) : JSON.stringify(body, (_, value: string) => (!emptyString && value === '' ? undefined : value));

export const buildWorkshopToolsRequest = <T extends Object>(
  url: string,
  method: HttpMethod,
  body?: T | FormData,
  emptyString?: boolean,
  isFileRequest?: boolean
): Promise<Response> => {
  const headers = buildHeaders('WORKSHOP', 'Bearer', true);
  const urlbuilt = buildUrl('WORKSHOP', url);
  const request = buildRequest(urlbuilt, method, headers, buildBody(body, emptyString, isFileRequest), isFileRequest);
  return fetchRequest(request);
};

export const buildWorkshopV3Request = (url: string, method: HttpMethod): Promise<Response> => {
  const headers = buildHeaders('WORKSHOP', 'Bearer', true);
  const urlbuilt = buildUrl('WORKSHOP_V3', url);
  const request = buildRequest(urlbuilt, method, headers);
  return fetchRequest(request);
};

export const getInterventionsIds = (getInterventionsIdsParams: GetInterventionsIdsParams): Promise<ToDoEvent[]> => {
  const { toDoEvents = [], processes, fromDate, toDate, nextParams = '' } = getInterventionsIdsParams;
  let params = '';

  if (processes) {
    params = `${params}&processes=${processes}`;
  }

  if (fromDate) {
    params = `${params}&start_date=${formatISOWithTimeZone(getStartOfDay(fromDate))}`;
  }

  if (toDate) {
    params = `${params}&end_date=${formatISOWithTimeZone(getEndOfDay(toDate))}`;
  }

  if (nextParams) {
    params = `${params}&${nextParams}`;
  }

  const url = `stores/${getStoreId()}/states/in_progress/interventions?${params || ''}`;
  return buildWorkshopToolsRequest(url, 'GET')
    .then(response => (response.status === 204 ? [] : response.json()))
    .then((interventions: any[]) =>
      toDoEvents.concat(
        interventions.map(intervention => ({
          date: new Date(intervention.availability_date),
          interventionIds: intervention.document_numbers,
        }))
      )
    )
    .catch(httpError => handleHttpErrors(httpError, 'intervention', logger, { url }));
};

const buildWorkshopInterventionFromRaw = (interventionRaw: any): WorkshopIntervention => {
  const intervention = { ...interventionRaw, deposited_product: interventionRaw.deposited_product || {} };
  const creationDate = new Date(intervention.creation_date);
  const availabilityDate = intervention.availability_date ? new Date(intervention.availability_date) : null;
  const purchaseDate = intervention.deposited_product.purchase_date ? new Date(intervention.deposited_product.purchase_date) : undefined;
  const workshopIntervention = camelcaseKeys<WorkshopIntervention>(intervention, { deep: true });

  return {
    ...workshopIntervention,
    creationDate,
    availabilityDate,
    depositedProduct: {
      ...workshopIntervention.depositedProduct,
      purchaseDate,
    },
  };
};

export const getWorkshopInterventionById = (interventionId: string, isInvoice: boolean, includes: string[] = []): Promise<WorkshopIntervention> => {
  const url = `stores/${getStoreId()}/interventions/${isInvoice ? 'invoice_numbers' : 'document_numbers'}/${interventionId}?include=${includes}`;
  return buildWorkshopV3Request(url, 'GET')
    .then(response => response.json())
    .then((result: WorkshopInterventionRaw) =>
      result.deposited_product?.item_code
        ? getArticlesDetails([result.deposited_product.item_code]).then(([model]) => ({
            ...result,
            deposited_product: {
              ...result.deposited_product,
              model_code: Number(model.modelId),
              label: model.modelLib,
            },
          }))
        : result
    )
    .then(buildWorkshopInterventionFromRaw)
    .catch(httpError => {
      if (httpError.response && httpError.response.status === 404) {
        throw translate('error.message.invalid.searched.text');
      }

      if (httpError.response && httpError.response.status === 403) {
        return httpError.response.json().then((errorDetails: any) => {
          if (errorDetails.error_id === '23') {
            throw translate('toaster.error.intervention.not.available.details', {
              documentNumber: truncateId(interventionId),
              stores: errorDetails.error.values.join(' - '),
            });
          } else {
            throw translate('toaster.error.forbidden.access');
          }
        });
      }
      return handleHttpErrors(httpError, 'intervention', logger, { url, interventionId });
    });
};

export const getCustomerAlert = (customerCommunication: CustomerCommunicationType, customer: Customer) => {
  if (customerCommunication === CustomerCommunicationType.BOTH) {
    if (Boolean(customer.email) && customer.emailAlert && Boolean(customer.phone) && customer.smsAlert) {
      return CustomerAlertType.BOTH;
    }
    if (Boolean(customer.email) && customer.emailAlert) {
      return CustomerAlertType.EMAIL;
    }
    if (Boolean(customer.phone) && customer.smsAlert) {
      return CustomerAlertType.SMS;
    }
  }
  if (customerCommunication === CustomerCommunicationType.EMAIL && Boolean(customer.email) && customer.emailAlert) {
    return CustomerAlertType.EMAIL;
  }
  if (customerCommunication === CustomerCommunicationType.PHONE_NUMBER && Boolean(customer.phone) && customer.smsAlert) {
    return CustomerAlertType.SMS;
  }
  return CustomerAlertType.NONE;
};

export const getInterventionCustomerAlert = (customer: Customer) => {
  if (Boolean(customer.email) && customer.emailAlert && Boolean(customer.phone) && customer.smsAlert) {
    return CustomerAlertType.BOTH;
  }
  if (Boolean(customer.email) && customer.emailAlert) {
    return CustomerAlertType.EMAIL;
  }
  if (Boolean(customer.phone) && customer.smsAlert) {
    return CustomerAlertType.SMS;
  }
  return CustomerAlertType.NONE;
};

export const getEmailAlert = (email: string | undefined, customerCommunication: CustomerCommunicationType) => {
  if (customerCommunication === CustomerCommunicationType.BOTH || customerCommunication === CustomerCommunicationType.EMAIL) {
    return Boolean(email);
  }
  return false;
};

export const getSMSAlert = (phoneNumber: string | undefined, customerCommunication: CustomerCommunicationType) => {
  if (customerCommunication === CustomerCommunicationType.BOTH || customerCommunication === CustomerCommunicationType.PHONE_NUMBER) {
    return Boolean(phoneNumber);
  }
  return false;
};

export const getDelaysInformation = (storeId: number): Promise<RegionalWorkshopDelay[]> => {
  const headers = buildHeaders('WORKSHOP', 'Bearer', true);
  const url = buildUrl('WORKSHOP', `stores/${storeId}/delays`);
  const request = buildRequest(url, 'GET', headers);
  return fetchRequest(request)
    .then(response => response.json())
    .then(data => camelcaseKeys<RegionalWorkshopDelay[]>(data, { deep: true }))
    .catch(httpError => handleHttpErrors(httpError, 'intervention', logger, { url }));
};

export const updateDelay = (storeId: number, processId: number, delay: number, registered: boolean) => {
  const url = `stores/${getStoreId()}/delays`;
  const body = {
    delay_overload: delay,
    family_id: processId,
    local_workshop: storeId,
  };

  return buildWorkshopToolsRequest(url, registered ? 'PATCH' : 'POST', body)
    .then(response => response.json())
    .then(result => camelcaseKeys(result, { deep: true }))
    .catch(httpError => handleHttpErrors(httpError, 'intervention', logger, { url }));
};

const getCreationResponse = (result: CreationResponseRaw): CreationResponse => ({
  docNumber: result.document_number,
  state: {
    stateId: result.state.state_id,
    stateLabel: result.state.state_label,
  },
});

interface BuildCustomerLanguageParams {
  customer?: Customer;
  i18nState: I18nState;
}

const buildCustomerLanguage = ({ customer, i18nState }: BuildCustomerLanguageParams) => {
  const { language, country } = customer?.language ?? i18nState.locale ?? {};

  if (!language || !country) return null;

  return `${language.toLowerCase()}-${country}`;
};

export const getInterventionInfos = (
  productItem: MasterDataArticle,
  product: ILinkProduct,
  realisationSiteId: number | undefined,
  isWarranty: boolean | undefined,
  interventionType: InterventionType,
  contextType: number | undefined,
  interventionId: string | undefined,
  processId: number,
  categoryId: number | undefined,
  storeId: number,
  footer: FooterInfos,
  customer: Customer | undefined,
  workshopComment: string | undefined,
  storeComment: string | undefined
) => {
  const depositedProduct: InterventionProduct = {
    itemCode: productItem.articleId,
    label: !productItem.articleId ? product.label : undefined,
    location: product.location,
    purchaseDate: product.purchaseDate ? formatISOWithTimeZone(product.purchaseDate) : undefined,
    categoryId,
  };

  return {
    isLocal: realisationSiteId === storeId,
    isWarranty: interventionType === InterventionType.homeService ? !!isWarranty : null,
    interventionType,
    contextId: contextType,
    family: processId,
    store: storeId,
    realisationSite: realisationSiteId,
    creationSite: storeId,
    depositedProduct,
    availabilityDate: formatISOWithTimeZone(footer.pledgeDate),
    memberId: customer ? customer.memberId : undefined,
    customerLanguage: buildCustomerLanguage({ customer, i18nState: store.getState().i18n }),
    customerAlertType: customer ? getInterventionCustomerAlert(customer) : undefined,
    storeComment: storeComment ?? '',
    workshopComment: workshopComment ?? '',
    traceabilityNumber: product.traceabilityNumber,
    stringTension: product.stringTension,
    externalReferenceNumber: interventionId,
  };
};

export const addFilesToIntervention = (docNumber: string, files: FileList | never[], storeId: number) => {
  const url = `stores/${storeId}/interventions/document_numbers/${docNumber}/files`;
  return Array.from(files).map(file => {
    const form = new FormData();
    form.append('file', file);
    return buildWorkshopToolsRequest(url, 'POST', form, false, true).catch(httpError =>
      handleHttpErrors(httpError, 'intervention', logger, { url, docNumber, fileName: file.name })
    );
  });
};

/**
 *
 * @deprecated use usePatchInterventionOrderInProgressMutation
 */
export const updateSparePartOrderInProgress = (docNumber: string, articleId: number, orderStatus: boolean): Promise<InterventionState> => {
  const url = `stores/${getStoreId()}/interventions/document_numbers/${docNumber}/spare_parts/${articleId}/order_in_progress`;
  const model = { order_in_progress: orderStatus };
  return buildWorkshopToolsRequest(url, 'PATCH', model)
    .then(response => response.json())
    .then(result => camelcaseKeys(result))
    .catch(httpError => handleHttpErrors(httpError, 'intervention', logger, { url, docNumber, data: JSON.stringify(model) }));
};

const filterPogoInInfinity = (a: ServicesPogoBody | SparePartsPogoBody) => a.pogo_id !== Infinity;

interface AddServicesAndSparePartsToIntervention {
  docNumber: string;
  services: Entity[];
  spareParts: SparePartEntity[];
  contextId: number;
  patchService: UseMutateAsyncFunction<ServiceWorkshopRaw, Error, UsePatchInterventionServiceMutationParams, unknown>;
  postServicesAndSpareParts: UseMutateAsyncFunction<ServicesAndSpareParts, Error, UsePostInterventionServicesAndSparePartsMutationParams, unknown>;
}

export const addServicesAndSparePartsToIntervention = ({
  docNumber,
  services,
  spareParts,
  contextId,
  patchService,
  postServicesAndSpareParts,
}: AddServicesAndSparePartsToIntervention): Promise<void | InterventionState | null> => {
  if (!services.length && !spareParts.length) {
    return Promise.resolve();
  }

  const workshopServices = services
    .map((service): ServicesPogoBody => {
      const selectedPogo = service.articles.find(article => article.selected)?.gridValueId;

      if (!selectedPogo) {
        return {
          pogoId: Infinity,
          quantity: Infinity,
          serviceModelCode: Infinity,
        };
      }

      const pogoId = translatePogoToPogoId(selectedPogo)!;

      return {
        pogoId,
        quantity: service.quantity,
        serviceModelCode: service.modelCode,
      };
    })
    .filter(filterPogoInInfinity);

  const workshopSpareParts = spareParts
    .map((sparePart): SparePartsPogoBody => {
      const selectedArticle = sparePart.articles.find(article => article.selected);
      const gridValueId = selectedArticle?.gridValueId;

      if (!selectedArticle || !gridValueId) {
        return {
          pogoId: Infinity,
          quantity: Infinity,
          sparePartCode: Infinity,
        };
      }

      const pogoId = translatePogoToPogoId(gridValueId)!;

      return {
        pogoId,
        quantity: sparePart.quantity,
        sparePartCode: selectedArticle.articleId,
        orderNumbers: sparePart.orderNumbers,
      };
    })
    .filter(filterPogoInInfinity);

  return postServicesAndSpareParts({
    interventionId: docNumber,
    payload: {
      contextId,
      spareParts: workshopSpareParts,
      services: workshopServices,
    },
  })
    .then(result => {
      const { interventionType: type, natures } = store.getState().intervention;
      const pecServices = natures.flatMap(nature => nature.services);

      if (type === InterventionType.homeService && result.services?.services) {
        handleEnforcedServices(docNumber, result.services.services, pecServices.concat(services), patchService, contextId);
      }

      const orderedArticles = spareParts
        .flatMap(sparePart => sparePart.articles)
        .filter(({ selected, orderInProgress }) => selected && orderInProgress);

      return Promise.all(orderedArticles.map(({ articleId }) => updateSparePartOrderInProgress(docNumber, articleId, true)));
    })
    .then(responses => (responses.length ? responses[0] : null));
};

interface CreateInterventionParams {
  quote?: boolean;
  patchService: UseMutateAsyncFunction<ServiceWorkshopRaw, Error, UsePatchInterventionServiceMutationParams, unknown>;
  postServicesAndSpareParts: UseMutateAsyncFunction<ServicesAndSpareParts, Error, UsePostInterventionServicesAndSparePartsMutationParams, unknown>;
}

export const createIntervention = ({
  patchService,
  postServicesAndSpareParts,
  quote = false,
}: CreateInterventionParams): Promise<CreationResponse> => {
  const state = store.getState();
  const storeId = selectStoreId(state);
  const authorizedPogos = selectAuthorizedPogos(state);
  const { product, breadcrumb } = state.catalog.search;
  const processId = breadcrumb.find(search => search.level === LevelType.categories)?.id;
  const categoryId = breadcrumb.find(search => search.level === LevelType.products)?.id;
  const { storeComment, workshopComment, realizationSite, isWarranty, interventionType, interventionRef, footer, files, contextType } =
    state.intervention;
  const services = state.intervention.natures.flatMap(nature => nature.services);
  const spareParts = state.intervention.natures.flatMap(nature => nature.spareParts);
  const isHomeService = interventionType === InterventionType.homeService;
  const { customer } = state.customer;

  const productItem =
    Number(product.articles.length) > 0 ? product.articles.find(article => article.selected) ?? product.articles[0] : ({} as MasterDataArticle);

  if (!processId) {
    return Promise.reject(new Error('Missing processId'));
  }

  if (!contextType?.id) {
    return Promise.reject(new Error('Missing contextId'));
  }

  const model = getInterventionInfos(
    productItem,
    product,
    realizationSite?.id,
    isWarranty,
    interventionType,
    contextType.id,
    interventionRef,
    processId,
    categoryId,
    storeId,
    footer,
    customer ?? undefined,
    workshopComment,
    storeComment
  );
  const url = `stores/${storeId}/interventions${quote ? '/quotes' : ''}`;

  return buildWorkshopToolsRequest(url, 'POST', snakecase(model))
    .then(response => response.json())
    .then(async (result: CreationResponseRaw) => {
      const enforcedServicesIds =
        isHomeService &&
        result.services &&
        (await handleEnforcedServices(result.document_number, result.services.services, services, patchService, contextType.id));
      const promises: Promise<any>[] = [];
      const servicesToSend = enforcedServicesIds ? services.filter(service => !enforcedServicesIds.includes(service.modelCode)) : services;
      promises.push(
        addServicesAndSparePartsToIntervention({
          docNumber: result.document_number,
          services: servicesToSend,
          spareParts,
          authorizedPogos,
          patchService,
          postServicesAndSpareParts,
          contextId: contextType.id,
        }).catch(errorMessage => ({ errorMessage }))
      );
      !!files &&
        !!files.length &&
        promises.push(
          ...addFilesToIntervention(result.document_number, files, storeId).map(promise => promise.catch(errorMessage => ({ errorMessage })))
        );
      return Promise.all(promises).then(responses => {
        const errorMessages: string[] = responses.filter(response => !!response && !!response.errorMessage).map(response => response.errorMessage);
        const newState = responses.find(response => !!response && !!response.stateId);
        return {
          ...getCreationResponse({ ...result, state: newState ? snakecase<InterventionStateRaw, snakecase.Options>(newState) : result.state }),
          reload: !!errorMessages.length,
          errorMessages,
        };
      });
    })
    .catch(httpError => handleHttpErrors(httpError, 'intervention', logger, { url, data: JSON.stringify(snakecase(model)) }));
};

export const updateComment = (docNumber: string, commentType: CommentType, comment: string) => {
  const model = { comment: comment || '', site_type: commentType };
  const url = `stores/${getStoreId()}/interventions/document_numbers/${docNumber}/comment`;
  return buildWorkshopToolsRequest(url, 'PATCH', model, true).catch(httpError =>
    handleHttpErrors(httpError, 'intervention', logger, { url, docNumber, data: JSON.stringify(model) })
  );
};

export const updateIntervention = async (
  docNumber: string,
  value: string | number | boolean | InterventionProduct,
  fieldName: string,
  patchIntervention: UseMutateAsyncFunction<unknown, Error, UsePatchInterventionMutationParams, unknown>
): Promise<any> => {
  const fields = fieldName.split('.');
  const updatedValue = fields.length === 1 ? { [fields[0]]: value } : { [fields[0]]: { [fields[1]]: value } };
  return patchIntervention({
    interventionId: docNumber,
    payload: updatedValue,
  });
};

export const updateCircuit = (docNumber: string, isLocal: boolean, realisationSite: number, storeId: number): Promise<InterventionState> => {
  const url = `stores/${storeId}/interventions/document_numbers/${docNumber}/circuit`;
  const model = snakecase({ isLocal, realisationSite });
  return buildWorkshopToolsRequest(url, 'PATCH', model)
    .then(response => response.json())
    .then(data => camelcaseKeys(data))
    .catch(httpError => handleHttpErrors(httpError, 'intervention', logger, { url, docNumber, data: JSON.stringify(model) }));
};

export const removeEntity = (docNumber: string, articleId: number, pogo: number, type: 'services' | 'spare_parts') => {
  const url = `stores/${getStoreId()}/interventions/document_numbers/${docNumber}/${type}/${articleId}/pogos/${pogo}`;
  return buildWorkshopToolsRequest(url, 'DELETE')
    .then(response => response.status === 200 && response.json())
    .then(state => state && camelcaseKeys(state))
    .catch(httpError => handleHttpErrors(httpError, 'intervention', logger, { url, docNumber }));
};

export const goToNextState = (docNumber: string, actualState: number, stateParam: StateParam = ''): Promise<InterventionState> => {
  const url = `stores/${getStoreId()}/interventions/document_numbers/${docNumber}/states${stateParam}`;
  const model = { current_state: actualState };
  return buildWorkshopToolsRequest(url, 'PATCH', model)
    .then(response => response.json())
    .then(data => camelcaseKeys(data, { deep: true }))
    .catch(httpError => handleHttpErrors(httpError, 'intervention', logger, { url, docNumber, data: JSON.stringify(model) }));
};

export const getShipmentPDF = (parcelNumber: string, isMobile?: boolean) => {
  const url = `stores/${getStoreId()}/interventions/parcel_numbers/${parcelNumber}/pdf/shipment`;
  return buildWorkshopToolsRequest(url, 'GET')
    .then(response => response.blob())
    .then(blob => (isMobile ? saveAs(blob, `${parcelNumber}.pdf`) : printBlob(blob)))
    .catch(httpError => handleHttpErrors(httpError, 'intervention', logger, { url }));
};

export const addWorkshopDefloc = (docNumber: string, categoryId: number, locationId: number, defaultId: number) => {
  const url = `stores/${getStoreId()}/interventions/document_numbers/${docNumber}/defloc`;
  const model = { category_id: categoryId, default_id: defaultId, location_id: locationId };
  return buildWorkshopToolsRequest(url, 'POST', model).catch((httpError: HttpError) => {
    if (!httpError.message.includes(`There is already a defloc for intervention ${docNumber}`)) {
      return handleHttpErrors(httpError, 'intervention', logger, { url, docNumber, data: JSON.stringify(model) });
    }

    return handleHttpErrors(httpError, 'intervention', logger, { url });
  });
};

export const addWorkshopRed = (docNumber: string, redInformations: RedInformations) => {
  const url = `stores/${getStoreId()}/interventions/document_numbers/${docNumber}/red`;
  const model = snakecase(redInformations as any);
  return buildWorkshopToolsRequest(url, 'POST', model)
    .then(response => response.json())
    .then(result => result.red_coupon)
    .catch(httpError => handleHttpErrors(httpError, 'intervention', logger, { url, docNumber, data: JSON.stringify(model) }));
};

export const getPendingSparePartInterventionsIds = (sparePartCode: number): Promise<string[]> => {
  const url = `stores/${getStoreId()}/spare_parts/${sparePartCode}/interventions`;
  return buildWorkshopToolsRequest(url, 'GET')
    .then(response => response.json())
    .catch(httpError => handleHttpErrors(httpError, 'intervention', logger, { url }));
};

export const getInterventionSpareParts = (docNumber: string): Promise<SparePartWorkshop[]> => {
  const url = `stores/${getStoreId()}/interventions/document_numbers/${docNumber}/spare_parts`;
  return buildWorkshopToolsRequest(url, 'GET')
    .then(response => response.json())
    .then(result => camelcaseKeys(result))
    .catch(httpError => handleHttpErrors(httpError, 'intervention', logger, { url, docNumber }));
};

const getNextParams = (headers: Headers) => {
  const nextHeader = headers.get('next');
  return nextHeader ? nextHeader.split('?')[1] : null;
};

export const getCustomerInterventionIds = async (customerId: string): Promise<string[]> => {
  const url = `stores/${getStoreId()}/interventions/customers/${customerId}`;
  try {
    const response = await buildWorkshopToolsRequest(url, 'GET');
    if (response.status === 204) {
      return [];
    }

    if (response.status === 206) {
      const result: string[] = [];
      result.push(...(await response.json()));

      let nextParams = getNextParams(response.headers);

      while (nextParams) {
        const nextResponse = await buildWorkshopToolsRequest(`${url}?${nextParams}`, 'GET');
        result.push(...(await nextResponse.json()));
        nextParams = getNextParams(nextResponse.headers);
      }
      return result;
    }
    return await response.json();
  } catch (httpError) {
    if (httpError instanceof HttpError) {
      if (httpError.response && httpError.response.status === 404) {
        return [];
      }
      return handleHttpErrors(httpError, 'intervention', logger, { url, customerId: customerId.toString() });
    }
    throw httpError;
  }
};

export const getLateInterventionIds = (): Promise<string[]> => {
  const url = `stores/${getStoreId()}/states/timeout/interventions`;
  return buildWorkshopToolsRequest(url, 'GET')
    .then(response => response.json())
    .catch(httpError => handleHttpErrors(httpError, 'intervention', logger, { url }));
};

export const getInterventionFileNames = (docNumber: string): Promise<string[]> => {
  const url = `stores/${getStoreId()}/interventions/document_numbers/${docNumber}/attached_files`;
  return buildWorkshopToolsRequest(url, 'GET')
    .then(response => (response.status === 200 ? response.json() : []))
    .catch(() => null);
};

export const deleteInterventionFiles = (docNumber: string, fileName: string) => {
  const url = `stores/${getStoreId()}/interventions/document_numbers/${docNumber}/files?file_name=${fileName}`;
  return buildWorkshopToolsRequest(url, 'DELETE', undefined, false, true).catch(httpError =>
    handleHttpErrors(httpError, 'intervention', logger, { url, docNumber, method: 'DELETE' })
  );
};

export const openInterventionFiles = (docNumber: string, fileName: string) => {
  const url = `stores/${getStoreId()}/interventions/document_numbers/${docNumber}/files?file_name=${fileName}`;
  return buildWorkshopToolsRequest(url, 'GET', undefined, false, true)
    .then(response => response.blob())
    .catch(httpError => handleHttpErrors(httpError, 'intervention', logger, { url, docNumber, method: 'GET' }));
};

export const sendWorkshopIntervention = (docNumbers: string[], containerId: number): Promise<ShippingResponse> => {
  const body = {
    packaging_id: containerId,
    document_numbers: docNumbers,
  };

  const url = `stores/${getStoreId()}/interventions/states/shipment`;
  return buildWorkshopToolsRequest(url, 'POST', body)
    .then(response => response.json())
    .then((result: RawShippingReturn) => ({
      docNumber: result.interventions[0].document_number,
      state: {
        stateId: result.interventions[0].state.state_id,
        stateLabel: result.interventions[0].state.state_label,
      },
      parcelNumber: result.parcel_number,
    }))
    .catch(httpError => handleHttpErrors(httpError, 'intervention', logger, { url, docNumbers, data: JSON.stringify(body) }));
};

export const getDelayInformation = (storeId: number): Promise<Delay[]> => {
  const key = `${interventionsStorageKey}-delay-${storeId}`;
  const storedDelays = cache.get(key);
  if (storedDelays) {
    return Promise.resolve(JSON.parse(storedDelays));
  }
  const headers = buildHeaders('WORKSHOP', 'Bearer', true);
  const url = buildUrl('WORKSHOP', `stores/${storeId}/parameters/delays`);
  const request = buildRequest(url, 'GET', headers);
  return fetchRequest(request)
    .then(response => (response.status === 204 ? [] : response.json()))
    .then(delays => {
      const result = camelcaseKeys<Delay[]>(delays);
      try {
        cache.set(key, JSON.stringify(result), minutesToMidnight());
      } catch (error) {
        cacheErrorLog(error);
      }
      return result;
    })
    .catch(httpError => handleHttpErrors(httpError, 'intervention', logger, { url }));
};

interface ConvertToQuotePayload {
  availability_date: string;
  customer_alert_type: string;
}

interface ConvertToQuoteProps {
  docNumber: string;
  availabilityDate: Date;
  storeId: number;
}
export const convertQuoteToOrder = ({ docNumber, availabilityDate, storeId }: ConvertToQuoteProps): Promise<InterventionState> => {
  const { customer } = store.getState().customer;
  if (!customer) {
    throw new Error(translate('intervention.page.customer.unfilled'));
  }
  const body: ConvertToQuotePayload = {
    availability_date: formatISOWithTimeZone(availabilityDate),
    customer_alert_type: getInterventionCustomerAlert(customer),
  };
  const url = `stores/${storeId}/interventions/document_numbers/${docNumber}/quotes/validation`;
  return buildWorkshopToolsRequest(url, 'PATCH', body)
    .then(response => response.json())
    .then(getCreationResponse)
    .then(result => result.state)
    .catch(httpError => handleHttpErrors(httpError, 'intervention', logger, { url, docNumber }));
};

export const getWorkshopInterventionStates = (storeId: number): Promise<InterventionState[]> => {
  const headers = buildHeaders('ICARE_BACK', 'Bearer', true);
  const url = buildUrl('ICARE_BACK', `workshop/interventions_states/${storeId}`);
  const request = buildRequest(url, 'GET', headers);
  return fetchRequest(request)
    .then(response => response.json())
    .then(result => camelcaseKeys<InterventionState[]>(result))
    .catch(httpError => handleHttpErrors(httpError, 'intervention', logger, { url }));
};

export const getWorkshopInterventionTypes = (storeId: number): Promise<InterventionType[]> => {
  const headers = buildHeaders('ICARE_BACK', 'Bearer', true);
  const url = buildUrl('ICARE_BACK', `workshop/interventions_types/${storeId}`);
  const request = buildRequest(url, 'GET', headers);
  return fetchRequest(request)
    .then(response => response.json())
    .then((result: InterventionTypeRaw[]) => result.map(interventionType => interventionType.intervention_id))
    .catch(httpError => handleHttpErrors(httpError, 'intervention', logger, { url }));
};

const isInterventionReceived = (
  intervention: ParcelIntervention,
  interventions: ParcelIntervention[],
  isPending: boolean,
  invoiceNumber?: string,
  searchNumber?: string
) => {
  if (searchNumber) {
    return [invoiceNumber, intervention.documentNumber].some(interventionId => searchNumber === interventionId) || !isPending;
  }
  return intervention.received || interventions.every(inter => !inter.received);
};

const isInterventionPending = (interventionStateId: number, isRealizationSite: boolean) =>
  interventionStateId === InterventionStatus.forReceptionInRW ? isRealizationSite : !isRealizationSite;

export const buildParcelInterventions = (parcel: Parcel, interventions: Intervention[], searchNumber?: string, forceNotReceived?: boolean) =>
  parcel.interventions.map(parcelIntervention => {
    const { selectedSite } = store.getState().userInfo;
    const intervention = interventions.find(i => i.interventionNumber === parcelIntervention.documentNumber);
    let isPending = false;
    let received = false;

    if (intervention) {
      isPending =
        !parcelIntervention.received && isInterventionPending(intervention.interventionStateId, selectedSite.id === intervention.realisationSiteId);
      received =
        !forceNotReceived && isInterventionReceived(parcelIntervention, parcel.interventions, isPending, intervention.invoiceNumber, searchNumber);
    }

    return {
      ...parcelIntervention,
      invoiceNumber: intervention?.invoiceNumber,
      received,
      product: intervention?.product,
      isPending,
      issued: !received && parcel.interventions.some(inter => inter.received),
    };
  });

const handleGetParcelError = (httpError: any, parcelNumber: string, url: string) => {
  if (httpError.response && httpError.response.status === 403) {
    throw translate('toaster.error.parcel.not.available', { parcel: parcelNumber });
  }
  if (httpError.response && httpError.response.status === 404) {
    throw translate('toaster.error.parcel.not.found', { parcel: parcelNumber });
  }
  return handleHttpErrors(httpError, 'intervention', logger, { url });
};

const transformParcel = (parcel: ParcelNumberRaw): Parcel => ({
  logisticalNumber: parcel.logistical_number,
  packagingId: parcel.packaging_id,
  parcelNumber: parcel.parcel_number,
  sender: parcel.sender,
  receiverSite: parcel.receiver_site,
  shipmentSite: parcel.shipment_site,
  shipmentDate: new Date(parcel.shipment_date),
  interventions: parcel.interventions.map(intervention => ({
    documentNumber: intervention.document_number,
    received: intervention.received,
    receptionDate: intervention.reception_date ? new Date(intervention.reception_date) : undefined,
  })),
});

export const getParcelInfo = (parcelNumber: string): Promise<Parcel> => {
  const headers = buildHeaders('WORKSHOP', 'Bearer', true);
  const url = buildUrl('WORKSHOP', `stores/${getStoreId()}/parcel_numbers/${parcelNumber}`);
  const request = buildRequest(url, 'GET', headers);
  return fetchRequest(request)
    .then(response => response.json())
    .then((parcelRaw: ParcelNumberRaw) => transformParcel(parcelRaw))
    .catch(httpError => handleGetParcelError(httpError, parcelNumber, url));
};

export const getLogisticalInformation = (interventionId: string): Promise<Parcel[]> => {
  const isInvoice = interventionId.length === 16;
  const headers = buildHeaders('WORKSHOP', 'Bearer', true);
  const url = buildUrl(
    'WORKSHOP',
    `stores/${getStoreId()}/interventions/${isInvoice ? 'invoice_numbers' : 'document_numbers'}/${interventionId}/logistical_information`
  );
  const request = buildRequest(url, 'GET', headers);

  return fetchRequest(request)
    .then(response => response.json())
    .then(data => camelcaseKeys<Parcel[]>(data, { deep: true }))
    .catch(httpError => {
      if (httpError.response && httpError.response.status === 403) {
        throw translate('toaster.error.intervention.not.available', { documentNumber: interventionId });
      }
      if (httpError.response && httpError.response.status === 404) {
        throw translate('toaster.error.intervention.not.found', { documentNumber: interventionId });
      }

      return handleHttpErrors(httpError, 'intervention', logger, { url });
    });
};

export const receiveParcel = (parcel: Parcel): Promise<Parcel> => {
  const body = {
    document_numbers: parcel.interventions
      .filter(intervention => intervention.received && intervention.isPending)
      .map(intervention => intervention.documentNumber),
  };
  const url = `stores/${getStoreId()}/parcel_numbers/${parcel.parcelNumber}/reception`;

  return buildWorkshopToolsRequest(url, 'PATCH', body)
    .then(response => response.json())
    .then(result => camelcaseKeys<Parcel>(result, { deep: true }))
    .then(result => ({
      ...result,
      parcelNumber: parcel.parcelNumber,
    }))
    .catch(httpError => handleHttpErrors(httpError, 'intervention', logger, { url, parcel: parcel.parcelNumber }));
};

export interface InterventionStatesPagination {
  total: number;
  rawInterventions: WorkshopIntervention[];
  futureParams: string;
}

export const getInterventionsByStates = (
  stateIds: number[],
  nextParams: string,
  isRealizationSite = false,
  isRegionalWorkshop?: boolean,
  fromDate?: Date,
  toDate?: Date,
  processes?: Filter[],
  interventionTypes?: Filter[],
  storeId?: number
): Promise<InterventionStatesPagination> => {
  let params = `&${nextParams}`;
  if (processes) {
    params = `${params}&processes=${processes.map(process => process.id)}`;
  }
  if (interventionTypes) {
    params = `${params}&intervention_types=${interventionTypes.map(interventionType => interventionType.id)}`;
  }
  if (fromDate) {
    params = `${params}&start_date=${formatISOWithTimeZone(getStartOfDay(fromDate))}`;
  }
  if (toDate) {
    params = `${params}&end_date=${formatISOWithTimeZone(getEndOfDay(toDate))}`;
  }
  if (storeId && isRegionalWorkshop && finishedInterventions.map(intervention => intervention[0]).includes(stateIds[0])) {
    params = `${params}&related_stores=${storeId}`;
  }

  const states = `states=${stateIds.join(',')}`;
  const includes = '&includes=type.context_code';
  const url = `stores/${getStoreId()}/interventions?${states}&is_realisation_site=${isRealizationSite.toString()}${params}${includes}`;
  const emptyResult: InterventionStatesPagination = { total: 0, rawInterventions: [], futureParams: '' };
  return buildWorkshopV3Request(url, 'GET')
    .then(response => {
      if (response.status === 204) {
        return emptyResult;
      }
      return response.json().then(interventions => ({
        total: Number(response.headers.get('x-total-count')),
        rawInterventions: interventions.map(buildWorkshopInterventionFromRaw),
        futureParams: getNextParams(response.headers),
      }));
    })
    .catch(httpError => {
      if (httpError.response && httpError.response.status === 404) {
        return emptyResult;
      }
      return handleHttpErrors(httpError, 'intervention', logger, { url });
    });
};

export const getInterventionsByIds = (interventionsIds: string[], include: string[]): Promise<WorkshopIntervention[]> => {
  const chunkInterventionsIds = chunkArray(interventionsIds, 10);
  return Promise.all(
    chunkInterventionsIds.map(partialInterventionsIds => {
      const urlParams = `document_number_list=${partialInterventionsIds}&include=${include}`;
      const url = buildUrl('WORKSHOP_V3', `stores/${getStoreId()}/interventions/document_numbers?${urlParams}`);
      const headers = buildHeaders('WORKSHOP_V3', 'Bearer', true);
      const request = buildRequest(url, 'GET', headers);
      return fetchRequest(request)
        .then(response => response.json())
        .then(interventions => interventions.map(buildWorkshopInterventionFromRaw))
        .catch(async httpError => {
          if (httpError instanceof HttpError) {
            if (httpError.response && httpError.response.status === 404) {
              return [] as WorkshopIntervention[];
            }
            if (httpError.response && httpError.response.status === 403) {
              throw await httpError.response.json();
            }
          }
          return handleHttpErrors(httpError, 'intervention', logger, { url });
        });
    })
  ).then(arrayOfInterventions => arrayOfInterventions.flatMap(interventions => interventions));
};

export const getFullParcel = async (parcelNumber: string, searchNumber?: string): Promise<Parcel> => {
  const headers = buildHeaders('WORKSHOP', 'Bearer', true);
  const url = buildUrl('WORKSHOP', `stores/${getStoreId()}/parcel_numbers/${parcelNumber}`);
  const request = buildRequest(url, 'GET', headers);

  try {
    const result = await (await fetchRequest(request)).json();
    const parcel = camelcaseKeys<Parcel>(result, { deep: true });

    if (parcel.receiverSite !== getStoreId()) {
      throw { errorCode: 999, message: translate('parcels.flow.not.existing.parcel') };
    }

    const documentNumbers = parcel.interventions.map(parcelIntervention => parcelIntervention.documentNumber);
    const workshopInterventions = await getInterventionsByIds(documentNumbers, ['state']);
    const interventions = await fillInterventionsInformation({ interventions: workshopInterventions, withoutIdentity: true });

    return {
      ...parcel,
      parcelNumber,
      interventions: buildParcelInterventions(parcel, interventions, searchNumber),
    };
  } catch (httpError) {
    if (httpError.errorCode === 999) {
      throw httpError.message;
    }
    return handleGetParcelError(httpError, parcelNumber, url);
  }
};

interface Historic {
  nextParams: string;
  parcels: Parcel[];
}

export const getHistoric = (
  startDate: Date,
  endDate: Date | undefined,
  isShipment: boolean,
  paginationParams: string,
  targetStoreId: number | null
): Promise<Historic> => {
  const start = getStartOfDay(startDate).toISOString();
  const end = getEndOfDay(endDate ?? startDate).toISOString();

  let params = `${paginationParams}&start_date=${start}&end_date=${end}`;
  if (targetStoreId) {
    params += `&${isShipment ? 'receiver' : 'sender'}=${targetStoreId}`;
  }

  const url = buildUrl('WORKSHOP', `stores/${getStoreId()}/parcels/historic/${isShipment ? 'shipments' : 'receptions'}?${params}`);

  const headers = buildHeaders('WORKSHOP', 'Bearer', true);
  const request = buildRequest(url, 'GET', headers);
  return fetchRequest(request)
    .then(response => {
      if (response.status === 204) {
        return { nextParams: '', parcels: [] };
      }

      return response
        .json()
        .then((parcelsRaw: ParcelNumberRaw[]) => parcelsRaw.map(transformParcel))
        .then(parcels => {
          const nextParams = response.status === 206 ? getNextParams(response.headers) : '';
          return { parcels, nextParams };
        });
    })
    .catch(httpError => {
      if (httpError.response && httpError.response.status === 404) {
        throw translate('history.filters.board.no.results.found');
      }
      return handleHttpErrors(httpError, 'intervention', logger, { url });
    });
};

interface ParcelsAndParams {
  parcels: Parcel[];
  nextParams: string;
  error?: string;
}

export const getParcelsToReceive = (params: string): Promise<ParcelsAndParams> => {
  const url = `stores/${getStoreId()}/parcels/receptions?${params}`;
  return buildWorkshopToolsRequest(url, 'GET')
    .then(response => {
      if (response.status === 204) {
        return { parcels: [], nextParams: '' };
      }

      return response.json().then((data: ParcelNumberRaw[]) => {
        const parcels = data.map(transformParcel);
        const nextParams = response.status === 206 ? getNextParams(response.headers) : '';
        const documentNumbers = parcels.flatMap(parcel => parcel.interventions.map(intervention => intervention.documentNumber));
        return getInterventionsByIds(documentNumbers, ['state'])
          .then(wsInterventions => fillInterventionsInformation({ interventions: wsInterventions, withoutIdentity: true }))
          .then(parcelsInterventions => ({
            parcels: parcels.map(parcelInfo => {
              const parcelInterventions = parcelsInterventions.filter(parcelIntervention =>
                parcelInfo.interventions.map(intervention => intervention.documentNumber).includes(parcelIntervention.interventionNumber)
              );
              const interventionsWithProduct = buildParcelInterventions(parcelInfo, parcelInterventions, undefined, true);
              return {
                ...parcelInfo,
                parcelNumber: parcelInfo.parcelNumber,
                interventions: interventionsWithProduct,
              };
            }),
            nextParams,
          }))
          .catch(() => ({
            parcels: [],
            nextParams,
            error: true,
          }));
      });
    })
    .catch(httpError => handle404Errors(httpError, logger, url, { nextParams: '', parcels: [] }));
};

interface WorkLoad {
  familyId: number;
  interventionsToReceiveCount: number;
  interventionsToReceiveTime: number;
  interventionsToTreatCount: number;
  interventionsToTreatTime: number;
}

export interface LoadForecast {
  familyId: number;
  toReceiveCount: number;
  toReceiveTime: number;
  toTreatCount: number;
  toTreatTime: number;
  totalCount: number;
  totalTime: number;
}

export const getLoadForecast = (): Promise<LoadForecast[]> => {
  const url = `stores/${getStoreId()}/workload`;
  return buildWorkshopToolsRequest(url, 'GET')
    .then(response => response.json())
    .then(data => camelcaseKeys<WorkLoad[]>(data, { deep: true }))
    .then(forecasts =>
      forecasts.map(forecast => ({
        familyId: forecast.familyId,
        toReceiveCount: forecast.interventionsToReceiveCount,
        toTreatCount: forecast.interventionsToTreatCount,
        toReceiveTime: forecast.interventionsToReceiveTime,
        toTreatTime: forecast.interventionsToTreatTime,
        totalCount: forecast.interventionsToTreatCount + forecast.interventionsToReceiveCount,
        totalTime: forecast.interventionsToTreatTime + forecast.interventionsToReceiveTime,
      }))
    )
    .catch(httpError => handleHttpErrors(httpError, 'intervention', logger, { url }));
};

export const getPurchaseInterventions = (memberId: string, articleId: number): Promise<PurchaseIntervention[]> => {
  const headers = buildHeaders('ICARE_BACK', 'Bearer', true);
  const url = buildUrl('ICARE_BACK', `workshop/customer_purchase_interventions/${memberId}/${articleId}`);
  const request = buildRequest(url, 'GET', headers);
  return fetchRequest(request)
    .then(response => response.json())
    .catch(httpError => handleHttpErrors(httpError, 'intervention', logger, { url }));
};

export const getInterventionsByCustomerIdAndArticleCode = (customerId: string, articleIds: number[]): Promise<WorkshopIntervention[]> => {
  const headers = buildHeaders('WORKSHOP_V3', 'Bearer', true);
  const url = buildUrl('WORKSHOP_V3', `customers/${customerId}/interventions?include=services&products=${articleIds}`);
  const request = buildRequest(url, 'GET', headers);
  return fetchRequest(request)
    .then(response => response.json())
    .then(data => camelcaseKeys<WorkshopIntervention[]>(data, { deep: true }))
    .catch(httpError => handle404Errors(httpError, logger, url, []));
};

export const updateLocation = (docNumber: string, location: string) => {
  const url = `stores/${getStoreId()}/interventions/document_numbers/${docNumber}/location`;
  const body = { location };

  return buildWorkshopToolsRequest(url, 'PATCH', body, true).catch(httpError => handleHttpErrors(httpError, 'intervention', logger, { url }));
};

export const getInterventionsFromExternalRef = (externalRef: string): Promise<SearchIntervention[]> => {
  const url = `external_reference_number/${externalRef}/interventions`;
  return buildWorkshopV3Request(url, 'GET')
    .then(response => response.json())
    .then(result => camelcaseKeys<WorkshopIntervention[]>(result, { deep: true }))
    .then(interventions =>
      Promise.all(
        interventions.map(intervention =>
          fetchFullCustomerById(intervention.memberId)
            .catch(() => null)
            .then(result => ({
              interventionNumber: intervention.documentNumber,
              customer: result ? getCustomerName(result) : '',
              product: intervention.depositedProduct.label,
              creationDate: new Date(intervention.creationDate),
              customerPledge: intervention.availabilityDate ? new Date(intervention.availabilityDate) : getCurrentDate(),
              estimatedTime: intervention.totalTheoreticalTime,
            }))
        )
      )
    )
    .catch(httpError => handleHttpErrors(httpError, 'intervention', logger, { url }));
};

export const getSearchNextParams = (last: boolean, pageNumber: number) => (last ? '' : `page=${pageNumber + 1}&size=15`);

export const getInterventionsByStateGlobalSearch = (url: string): Promise<InterventionStatesPagination> => {
  const emptyResult: InterventionStatesPagination = { total: 0, rawInterventions: [], futureParams: '' };

  return buildWorkshopV3Request(url, 'GET')
    .then(response => response.json())
    .then((wboSearchInterventions: WboGlobalSearchRaw) => {
      if (wboSearchInterventions.empty) {
        return emptyResult;
      }
      const formattedInterventions = wboSearchInterventions.content.map(intervention => buildGlobalSearchInterventionFromRaw(intervention));
      return {
        total: wboSearchInterventions.total_elements,
        rawInterventions: formattedInterventions,
        futureParams: getSearchNextParams(wboSearchInterventions.last, wboSearchInterventions.pageable.page_number),
      };
    })
    .catch(httpError => {
      if (httpError.response && httpError.response.status === 404) {
        return emptyResult;
      }
      return handleHttpErrors(httpError, 'intervention', logger, { url });
    });
};
