import { InterventionType } from '../apis/interventions/intervention.type';
import { selectIsPogoInternalPaying } from './pogoHelper';
import type { ActionsLabelsState } from '../store/selectors/intervention';
import store from '../store/store';

export enum Action {
  duplicate,
  cancel,
  validate,
  print,
  printInvoice,
  toDelete,
  editInvoice,
  send,
  receive,
  refuse,
  close,
  createQuote,
  convertQuote,
  printQuote,
  cancelShipment,
}

export enum InterventionStatus {
  notStarted = -1,
  inCreation = 0,
  estimate = 1,
  // itv_state_principal.quote	Estimate  /  Devis
  toProcess = 2,
  // itv_state_principal.to_process	To process  /  A traiter
  processedAndAvailable = 3,
  // itv_state_principal.done_available_customer	Processed and available  /  Traité et disponible
  paidClosed = 4,
  // itv_state_principal.payed_closed	Paid/closed  /  Payé / clôturé
  deleted = 5,
  // itv_state_principal.deleted	Deleted  /  Supprimé
  sendToRW = 6,
  // itv_state_principal.to_be_sent_in_regional_workshop	To be sent to RW  /  A envoyer en AR
  storeShortage = 7,
  // itv_state_principal.store_stock_shortage	Store shortage  /  Attente pièce
  forReceptionInRW = 8,
  // itv_state_principal.transportation_to_regional_workshop	For reception in the regional workshop  /  A réceptionner en atelier régional
  receivedForProcessingInRW = 9,
  // itv_state_principal.delivered_to_process_in_regional_workshop	Received, for processing in RW  /  Réceptionné, à traiter en AR
  closureUnderway = 10,
  // itv_state_principal.closing_in_progress	Closure underway  /  Clôture en cours
  RWShortage = 11,
  // itv_state_principal.regional_workshop_stock_shortage	RW shortage  /  Attente pièce AR
  processedToBeRedirected = 12,
  // itv_state_principal.done_to_return	Processed, to be redirected  /  Traité, à réexpédier
  forReceptionInStore = 13,
  // itv_state_principal.transportation_to_store	For reception in store  /  A réceptionner en magasin
  ogeaValidity = 41,
  // itv_state_principal.ogea_validated	OGEA validity  /  Validé OGEA
  refusedToBeRedirected = 58,
  // itv_state_principal.refused_to_return	Refused, to be redirected  /  Refusé, à réexpédier
  closed = 59,
  // itv_state_principal.closed	Closed  /  Clôturé
  refusedAndAvailable = 60,
  // itv_state_principal.refused_available_customer	Refused, available  /  Refusé,  disponible
}

export const getActionsLabels = (actionLabelsState: ActionsLabelsState): Action[] => {
  const { stateId, hasShortage, isCreationSite, isRealizationSite, interventionType, isInvoice, isInternal, isPayablePogo, isTotalFree, isToDelete } =
    actionLabelsState;
  const isStandard = interventionType === InterventionType.standard;
  const isWeb = interventionType === InterventionType.web;
  const isHomeService = interventionType === InterventionType.homeService;
  const {
    toProcess,
    receivedForProcessingInRW,
    sendToRW,
    processedToBeRedirected,
    forReceptionInRW,
    storeShortage,
    RWShortage,
    forReceptionInStore,
    processedAndAvailable,
    paidClosed,
    closureUnderway,
    ogeaValidity,
    refusedToBeRedirected,
    refusedAndAvailable,
    estimate,
    closed,
    inCreation,
  } = InterventionStatus;
  const state = store.getState();
  const configState = state.configuration;
  const isPogoInternalPaying = selectIsPogoInternalPaying(state);
  const isFreeClosingAuthorized = configState?.isFreeClosingAuthorized;
  const closeOrEdit = isTotalFree ? Action.close : Action.editInvoice;

  if (isToDelete) {
    return [Action.toDelete];
  }

  if (isWeb && stateId === toProcess) {
    return [Action.print, Action.close];
  }

  if ((stateId === toProcess && (isHomeService || (isPogoInternalPaying && isInternal))) || stateId === refusedAndAvailable) {
    return [Action.close, Action.print, Action.toDelete];
  }

  if (stateId === toProcess) {
    return [
      ...(isStandard && !isInternal ? [Action.duplicate] : []),
      ...(isInternal ? [closeOrEdit] : [Action.editInvoice]),
      Action.print,
      Action.toDelete,
    ];
  }

  if (stateId === receivedForProcessingInRW && !isCreationSite && isInternal) {
    return [Action.editInvoice, Action.print, Action.toDelete];
  }

  if (stateId === receivedForProcessingInRW && !isCreationSite) {
    return [Action.editInvoice, Action.print, Action.refuse, Action.toDelete];
  }

  if (stateId === sendToRW && isCreationSite) {
    return (isStandard && !isInternal ? [Action.duplicate] : []).concat([Action.send, Action.print, Action.toDelete]);
  }

  if (stateId === processedToBeRedirected) {
    return isCreationSite ? [Action.printInvoice] : [Action.send, Action.printInvoice, Action.toDelete];
  }

  if (forReceptionInRW === stateId) {
    return (isCreationSite ? [Action.cancelShipment] : [])
      .concat(isStandard && isCreationSite && !isInternal ? [Action.duplicate] : [])
      .concat(!isCreationSite ? [Action.receive] : [])
      .concat([Action.print]);
  }

  if ([storeShortage, RWShortage, sendToRW].includes(stateId) || (stateId === receivedForProcessingInRW && isCreationSite)) {
    return [Action.print];
  }

  if (forReceptionInStore === stateId) {
    return (isCreationSite ? [Action.receive] : []).concat([isInvoice ? Action.printInvoice : Action.print]);
  }

  if (isFreeClosingAuthorized && stateId === processedAndAvailable && isStandard && !isInternal && isTotalFree) {
    return [Action.close, Action.duplicate, Action.printInvoice, Action.toDelete];
  }

  if ([processedAndAvailable, paidClosed, closureUnderway].includes(stateId)) {
    return (isStandard && !isInternal && isCreationSite ? [Action.duplicate] : []).concat(
      stateId === processedAndAvailable && isTotalFree ? Action.close : [],
      Action.printInvoice,
      stateId === processedAndAvailable && (!isInternal || isCreationSite) ? Action.toDelete : []
    );
  }

  if (stateId === ogeaValidity) {
    return [Action.validate, Action.toDelete];
  }

  if (stateId === refusedToBeRedirected) {
    return (!isCreationSite ? [Action.send] : []).concat([Action.print, Action.toDelete]);
  }

  if (stateId === estimate && isCreationSite) {
    return [Action.convertQuote, Action.printQuote, Action.toDelete];
  }

  if (stateId === estimate) {
    return [Action.printQuote];
  }

  if (stateId === closed && isCreationSite) {
    return [Action.duplicate];
  }

  if (stateId === inCreation && (isHomeService || isInternal)) {
    return (!isPogoInternalPaying && isRealizationSite && isPayablePogo ? [Action.editInvoice] : []).concat([Action.validate, Action.cancel]);
  }

  // Default case = inCreation
  return (isRealizationSite && !hasShortage ? [Action.editInvoice] : []).concat([Action.validate, Action.createQuote, Action.cancel]);
};
