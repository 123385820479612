import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { type Booking } from '../apis/bookings/bookings.types';

interface BookingState {
  booking?: Booking;
  onContinueWithBooking?: (booking?: Booking) => void;
}

const initialState: BookingState = {
  booking: undefined,
  onContinueWithBooking: undefined,
};

const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    setBooking: (state, action: PayloadAction<Booking>) => {
      state.booking = action.payload;
    },
    setOnContinueWithBooking: (state, action: PayloadAction<(booking?: Booking) => void>) => {
      state.onContinueWithBooking = action.payload;
    },
    clearOnContinueWithBooking: state => {
      state.onContinueWithBooking = undefined;
    },
    resetBooking: () => initialState,
  },
  extraReducers: builder => builder.addCase('resetStore', () => initialState),
});

export const {
  reducer: bookingReducer,
  actions: { setBooking, setOnContinueWithBooking, clearOnContinueWithBooking, resetBooking },
} = bookingSlice;
