import { createSelectorHook, type TypedUseSelectorHook, useDispatch } from 'react-redux';
import { combineReducers, configureStore, createAction } from '@reduxjs/toolkit';
import { reduxAjaxMiddleware, reduxAjaxReducer, reduxAjaxReducerKey } from '@chipp972/redux-ajax';

import { fetchForRedux } from '../utils/apis/RestApi';
import { addTokenToReduxRequest, appVersionChecker } from './middlewares/middlewares';
import { configReducer } from './configSlice';
import { i18nReducer } from './i18nSlice';
import { customerReducer } from './customerSlice';
import { catalogReducer } from './catalogSlice';
import { interventionReducer } from './interventionSlice';
import { deflocReducer } from './deflocSlice';
import { userInfoReducer } from './userInfoSlice';
import { storeListReducer } from './store-list';
import { workshopInterventionReducer } from './workshopInterventionSlice';
import { appReducer } from './appSlice';
import { authReducer } from './authSlice';
import { bookingReducer } from './bookingSlice';

export const rootReducer = combineReducers({
  auth: authReducer,
  app: appReducer,
  configuration: configReducer,
  i18n: i18nReducer,
  customer: customerReducer,
  catalog: catalogReducer,
  intervention: interventionReducer,
  defloc: deflocReducer,
  workshopIntervention: workshopInterventionReducer,
  storeList: storeListReducer,
  userInfo: userInfoReducer,
  booking: bookingReducer,
  [reduxAjaxReducerKey]: reduxAjaxReducer,
});

const store = configureStore({
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      appVersionChecker,
      reduxAjaxMiddleware({ fetchFn: fetchForRedux }),
      addTokenToReduxRequest
    ),
  reducer: rootReducer,
});

export const resetStore = createAction('resetStore');

export type RootState = ReturnType<typeof store.getState>;

type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
/**
 * @deprecated use `useSelector` from 'react-redux' instead
 */
export const useAppSelector: TypedUseSelectorHook<RootState> = createSelectorHook();

export default store;
