import { useSelector } from 'react-redux';

import { selectSelectedSite } from '../../store/selectors/userInfo';
import { removeSpacesBetweenHyphen } from '../utils';

export const useDataStudio = () => {
  const {
    id: storeId,
    name,
    address: { countryCode },
  } = useSelector(selectSelectedSite);

  const regionNamesInEnglish = new Intl.DisplayNames(['en'], {
    type: 'region',
  });

  const countryName = regionNamesInEnglish.of(countryCode);
  const storeNameWithoutSpaces = removeSpacesBetweenHyphen(name);

  const baseUrl =
    'https://prod-uk-a.online.tableau.com/#/site/dktunited/views/Workshop-HomePage_17283911461640/Homepage?:' +
    `iid=${storeId}&country_name=${countryName}&creation_site=${storeNameWithoutSpaces}`;

  return encodeURI(baseUrl);
};
