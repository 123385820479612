import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import cache from 'lscache';

import type { Site } from '../apis/userinfo.type';
import { CustomerCommunicationType } from '../apis/startup/startup.type';
import { storeStorageKey } from '../components/auth/constants';

export interface IcarePersonalUserInfo {
  displayName: string;
  preferredLanguage: string;
  uid: string;
  jobname?: string;
  sitePartyNumber: string;
}

export interface IcareSiteUserInfo {
  selectedSite: Site;
  customerCreationRequiredFields: {
    [key: string]: boolean;
  };
  crmZone: string;
  regionalWorkshopId: number | null;
  countryId?: number;
  hasSADNewProduct?: boolean;
  isAllocationAuthorized?: boolean;
  isPurchaseActive?: boolean;
  customerCommunication: CustomerCommunicationType;
  traceabilityActivation?: boolean;
  newCustomerPurchasesPage?: boolean;
  isBookingsEnabled?: boolean;
  hasRWRelatedStores?: boolean;
}

export type IcareUserInfo = IcarePersonalUserInfo & IcareSiteUserInfo;

export const initialState: IcareUserInfo = {
  displayName: '',
  preferredLanguage: '',
  regionalWorkshopId: null,
  sitePartyNumber: '',
  selectedSite: {
    address: {
      countryCode: '',
    },
    id: cache.get(storeStorageKey) ?? 0,
    isRegionalWorkshop: false,
    name: '',
    shortName: '',
    currency: '',
    maxExpressAmount: 0,
    allowSad: false,
  },
  uid: '',
  crmZone: '',
  customerCreationRequiredFields: {},
  customerCommunication: CustomerCommunicationType.BOTH,
};

const userInfoSlice = createSlice({
  name: 'userinfo',
  initialState,
  extraReducers: builder => builder.addCase('resetStore', () => initialState),
  reducers: {
    addPersonalUserInfo: (state, action: PayloadAction<IcarePersonalUserInfo>) => ({
      ...state,
      displayName: action.payload.displayName,
      preferredLanguage: action.payload.preferredLanguage,
      uid: action.payload.uid,
      jobname: action.payload.jobname,
      sitePartyNumber: action.payload.sitePartyNumber,
    }),
    addUserInfo: (state, action: PayloadAction<IcareSiteUserInfo>) => ({
      ...state,
      countryId: action.payload.countryId,
      crmZone: action.payload.crmZone,
      customerCreationRequiredFields: action.payload.customerCreationRequiredFields,
      hasSADNewProduct: action.payload.hasSADNewProduct,
      isAllocationAuthorized: action.payload.isAllocationAuthorized,
      traceabilityActivation: action.payload.traceabilityActivation,
      isPurchaseActive: action.payload.isPurchaseActive,
      regionalWorkshopId: action.payload.regionalWorkshopId,
      selectedSite: action.payload.selectedSite,
      customerCommunication: action.payload.customerCommunication,
      newCustomerPurchasesPage: action.payload.newCustomerPurchasesPage,
      isBookingsEnabled: action.payload.isBookingsEnabled,
    }),
  },
});

export const {
  reducer: userInfoReducer,
  actions: { addUserInfo, addPersonalUserInfo },
} = userInfoSlice;
